<template>
  <div>
    <topBarY />
    <div class="bg">
      <img src="../../../assets/common/pic/bgs.jpg" alt="">
    </div>
    <div class="switchBar">
      <div class="tab contact">工程案例实装</div>
    </div>
    <div class="line"></div>
    <div class="switchContiner">
      <div class="continer contactContiner">
        <div class="case">
          <div class="one" @click="caseOne">
            <img src="../../../assets/support/bus.jpg" alt="">
            <div class="txt">杭州BRT快速公交无线&nbsp;...</div>
          </div>
          <div class="two" @click="caseTwo">
            <img src="../../../assets/support/oilField.jpg" alt="">
            <div class="txt">青海油田无线传输监控&nbsp;...</div>
          </div>
          <div class="three" @click="caseThree">
            <img src="../../../assets/support/park.jpg" alt="">
            <div class="txt">云龙公园无线视频监控&nbsp;...</div>
          </div>
        </div>
      </div>
    </div>
    <baseBar />
  </div>
</template>
  
<script>
import topBarY from '@/components/topBarY.vue'
import baseBar from '@/components/baseBar.vue'

export default {
  name: 'Engineering',
  components: {
    topBarY,
    baseBar
  },
  data() {
    return {
      
    }
  },
  methods: {
    caseOne() {
      this.$router.push('caseOne')
    },
    caseTwo() {
      this.$router.push('caseTwo')
    },
    caseThree() {
      this.$router.push('caseThree')
    }
  },
  created() {
    this.$nextTick(() => {
      // 1.禁用右键菜单
      document.oncontextmenu = new Function("event.returnValue=false");
      // 2.禁用鼠标选中
      document.onselectstart = new Function("event.returnValue=false");
      // 3.禁止键盘F12键
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault(); // 如果按下键F12,阻止事件
        }
      });
    });
  }
}
</script>
  
<style scoped>
.bg {
  position: relative;
  width: 1920px;
  height: 480px;
  overflow: hidden;
}

.bg img {
  position: absolute;
  top: -100px;
  width: 1920px;
}

.switchBar {
  width: 1920px;
  height: 70px;
  background-color: #fff;
  text-align: center;
}

.switchBar .tab {
  display: inline-block;
  margin-right: 30px;
  margin-top: 15px;
  border-radius: 5px;
  width: 140px;
  height: 40px;
  background-color: #c3a35d;
  color: #f5f5f5;
  font-family: 'Microsoft Yahei';
  font-size: 16px;
  line-height: 40px;
}

.line {
  margin: 0 auto;
  width: 1400px;
  height: 1px;
  background-color: #c3a35d;
}

.switchContiner {
  width: 1920px;
  padding: 20px;
  background-color: #f7f7f7;
}

.switchContiner .contactContiner {
  margin: 0 auto;
  padding: 40px;
  width: 1400px;
  height: 330px;
  border-radius: 5px;
  background-color: #fff;
  text-align: center;
}

.switchContiner .contactContiner .case .txt{
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 18px;
}

.switchContiner .contactContiner .case img{
  width: 260px;
  height: 175px;
}

.switchContiner .contactContiner .one {
  position: relative;
  display: inline-block;
  margin-right: 40px;
  background-color: #f5f5f5;
  width: 260px;
  height: 240px;
}

.switchContiner .contactContiner .one:hover {
  box-shadow: 0 3px 5px 1px rgb(32 32 32 / 30%);
  transform: translate3d(0, -2px, 0);
  transition: all 0.2s linear;
}

.switchContiner .contactContiner .two {
  position: relative;
  display: inline-block;
  margin-right: 40px;
  background-color: #f5f5f5;
  width: 260px;
  height: 240px;
}

.switchContiner .contactContiner .two:hover {
  box-shadow: 0 3px 5px 1px rgb(32 32 32 / 30%);
  transform: translate3d(0, -2px, 0);
  transition: all 0.2s linear;
}

.switchContiner .contactContiner .three {
  position: relative;
  display: inline-block;
  background-color: #f5f5f5;
  width: 260px;
  height: 240px;
}

.switchContiner .contactContiner .three:hover {
  box-shadow: 0 3px 5px 1px rgb(32 32 32 / 30%);
  transform: translate3d(0, -2px, 0);
  transition: all 0.2s linear;
}
</style>
  