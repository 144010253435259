<template>
    <!-- footer区域 Start -->
    <div class="footer">
        <div class="logo">
            <img src="../assets/common/pic/logoRW.png" alt="" class="logopic">
        </div>
        <div class="content">
            <div class="msg">
                <div class="address">
                    <img src="../assets/common/pic/address.png" alt="">
                    <span>浙江省杭州市西湖区振华路298号西港发展中心西4幢3楼</span>
                </div>
                <div class="phone">
                    <img src="../assets/common/pic/phone.png" alt="">
                    <span>400-068-6917</span>
                </div>
                <div class="email">
                    <img src="../assets/common/pic/email.png" alt="">
                    <span>销售咨询：sales@linkom.com.cn</span><br>
                    <p class="em">技术咨询：support@linkom.com.cn</p>
                </div>
            </div>
            <div class="map">
                <div class="nav tit">快速导航</div>
                <router-link to="/productCenter" class="nav ">产品中心</router-link><br />
                <router-link to="/solution" class="nav ">解决方案</router-link><br />
                <router-link to="/support" class="nav ">文档支持</router-link><br />
                <router-link to="/about" class="nav ">关于宁讯</router-link><br />
            </div>
            <div class="service">
                <div class="nav tit">服务中心</div>
                <router-link to="/service" class="nav ">售前服务</router-link><br />
                <router-link to="/service" class="nav ">售后服务</router-link><br />
            </div>
            <div class="pic">
                <img src="../assets/common/pic/publicAccount.jpg" alt="" class="ourpic">
                <div class="text">关注我们 获取更多资讯</div>
            </div>
        </div>
        <div class="txt">
            专&nbsp;注&nbsp;无&nbsp;线&nbsp;传&nbsp;输&nbsp;产&nbsp;品&nbsp;及&nbsp;方&nbsp;案
        </div>
    </div>
    <!-- footer区域 End -->
</template>

<script>
export default {
    name: 'baseBar'
}
</script>

<style scoped>
/* footer区域 Start */
.footer {
    width: 1920px;
    background-color: #c3a35d;
}

.footer .logo {
    width: 1400px;
    height: 70px;
    margin: 0 auto;
    border-bottom: 2px solid #f5f5f5;
}

.footer .logo .logopic {
    width: 130px;
    margin-top: 12px;
}

.footer .content {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 40px;
}

.footer .content .msg {
    text-align: left;
}

.footer .content .msg .address,
.phone,
.email,
.public {
    margin-bottom: 10px;
}

.footer .content .msg img {
    width: 16px;
    margin-right: 10px;
}

.footer .content .msg span {
    color: #f5f5f5;
    font-size: 12px;
}

.footer .content .email .em {
    font-size: 12px;
    color: #f5f5f5;
    text-indent: 2em;
}

.footer .map {
    margin-left: 80px;
    margin-right: 20px;
    margin-top: 12px;
}

.footer .service {
    margin-top: 12px;
}

.footer .map .nav {
    color: #796539;
    margin-bottom: 5px;
    font-size: 10px;
}

.footer .map .nav:hover {
    color: #f5f5f5;
}

.footer .service .nav {
    color: #796539;
    margin-bottom: 5px;
    font-size: 10px;
}

.footer .service .nav:hover {
    color: #f5f5f5;
}

.footer .map,
.service {
    display: inline-block;
    text-align: left;
    vertical-align: top;
}

.footer .map .tit {
    color: #f5f5f5;
    font-size: 12px;
}

.footer .service .tit {
    color: #f5f5f5;
    font-size: 12px;
}

.footer .pic {
    margin-left: 60px;
    margin-top: 20px;
    display: inline-block;
    text-align: center;
}

.footer .pic img {
    width: 80px;
    height: 80px;
}

.footer .pic .text {

    font-size: 10px;
    color: #fff;
}

.footer .txt {
    margin-top: 38px;
    width: 1920px;
    height: 20px;
    line-height: 20px;
    font-family: kaiti;
    background-color: #b49756;
    color: #796539;
    font-size: 12px;
    text-align: center;
}

.footer .txt:hover {
    color: #f5f5f5;
}

/* footer区域 End */
</style>
